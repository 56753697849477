.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 80px);
  align-items: center;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.recognizingOuter {
  overflow: hidden;
  white-space: nowrap;
  width: 95%;
}
.recognizing {
  height: 48px;
  font-size: 24px;
  margin: 16px;
  float: right;
  white-space: nowrap;
}

.recognized {
  font-size: 18px;
  color: #444;
  width: 95%;
  height: calc(100vh - 200px);
  margin: 16px;
  overflow: auto;
  border: 3px double #1976d2;
  border-radius: 12px;
}

.sentence {
  padding: 2px;
  padding-left: 6px;
}

.header button {
  margin: 12px;
  font-size: 32px;
}

.running {
  background-color: red;
}

.stopped {
  background-color: #1976d2;
}
