html {
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  /* touch-action: none; */
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: goldenrod;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
  user-select: none;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: goldenrod;
}

::-webkit-scrollbar-thumb {
  background: #ab831f;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  border-radius: 1ex;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.App {
  text-align: center;
}
