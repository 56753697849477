.button {
  color: white;
  padding: 6px 14px;
  border-radius: 12px;
  margin: 8px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.25s ease;
}

@media (hover: hover) and (pointer: fine) {
  .container button:hover {
    opacity: 0.5;
  }
}
